import request from '@/utils/request';
import { BFZ_BFF, AFT_AIGC, AFT_CONTENT } from '@/constant/api';
let casModule = '/cas';

export default {
  //批量修改选题
  batchEditSelectionTagApi(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/matrix/manager/picture/libV2/batchEditSelectionTag`,
      method: 'post',
      data,
    });
  },
  //批量修改标签
  batchEditTagApi(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/matrix/manager/picture/libV2/batchEditTag`,
      method: 'post',
      data,
    });
  },
  //图片库编辑选题
  editSelectionTagApi(data, fileId) {
    return request({
      baseURL: BFZ_BFF,
      url: `/matrix/manager/picture/libV2/${fileId}/editSelectionTag`,
      method: 'post',
      data,
    });
  },
  //分页查询车系关联的prompt
  getXhsAiImageCarSeriesPromptList(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsAiImageCarSeriesPrompt/page`,
      method: 'get',
      params: params,
    });
  },
  //新增ai作图-车系关联的prompt
  addXhsAiImageCarSeriesPrompt(data) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsAiImageCarSeriesPrompt`,
      method: 'post',
      data,
    });
  },
  //根据车系id查询prompt
  findByCarSeriesId(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsAiImageCarSeriesPrompt/findByCarSeriesId`,
      method: 'get',
      params: params,
    });
  },
  //根据id删除prompt
  deleteByCarSeriesId(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsAiImageCarSeriesPrompt/${params.id}`,
      method: 'delete',
    });
  },
  //笔记推送页面查询分页
  pushPageApi(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/note/push/page`,
      method: 'post',
      data,
    });
  },
  //媒体号列表新增编辑媒体号
  addAndEditMasterAccountAndPersonalityApi(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/manager/xhs/author/matrix/manager/xhs/author/addAndEditMasterAccountAndPersonality`,
      method: 'post',
      data,
    });
  },
  //运营执行立即生产内容
  doPreGenerateApi() {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/preGenerate/doPreGenerate`,
      method: 'post',
    });
  },
  //编辑文案模板
  updateCopywritingTemplate(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/copywritingTemplate`,
      method: 'put',
      data,
    });
  },
  // 查询文案模板
  getCopywritingTemplate(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/copywritingTemplate`,
      method: 'get',
      params: params,
    });
  },
  //删除文案模板
  deleteCopywritingTemplate(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/copywritingTemplate`,
      method: 'delete',
      params: params,
    });
  },
  //新增文案模板
  addCopywritingTemplate(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/copywritingTemplate`,
      method: 'post',
      data,
    });
  },
  //获取文案模板
  getCopywritingTemplateList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/copywritingTemplate/page`,
      method: 'post',
      data,
    });
  },
  // 获取干货类故事
  getXhsSubstanceNoteApi(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aigc/xhsSubstanceNote`,
      method: 'post',
      data,
    });
  },
  // 获取标签
  getAigcTagListApi() {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/aigc/tagList',
      method: 'get',
    });
  },
  getAigcXhsTaskList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/tasks`,
      method: 'post',
      data,
      params: {
        page: data.page,
        size: data.size,
      },
    });
  },
  getAigcXhsTaskDetail(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/task/${params.taskId}`,
      method: 'get',
    });
  },
  updateAigcXhsTaskDetail(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/task/${data.taskId}`,
      method: 'post',
      data,
    });
  },
  newAigcXhsTask(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/task`,
      method: 'post',
      data,
    });
  },
  // 文案库列表
  getAigcSeriesFeaturesList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/aigc/series/features/list',
      method: 'post',
      data,
      params: {
        page: data.page,
        size: data.size,
      },
    });
  },
  // 编辑/修改卖点
  updateAigcXhsSeriesFeature(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/series/features/${data.seriesFeatureId}`,
      method: 'put',
      data,
    });
  },
  // 新增卖点
  newAigcSeriesFeature(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/series/features`,
      method: 'post',
      data,
    });
  },
  getAigcNoteFeedbacksList(data) {
    return request({
      baseURL: AFT_AIGC,
      url: `/aigc_xhs_note_feedbacks`,
      method: 'post',
      data,
      params: {
        page: data.page,
        size: data.size,
      },
    });
  },
  updateAigcNoteFeedbackFeature(data) {
    return request({
      baseURL: AFT_AIGC,
      url: `/aigc_xhs_note_feedback/${data.note_feedback_id}`,
      method: 'post',
      data,
    });
  },
  // newAigcNoteFeedback(data) {
  //   return request({
  //     baseURL: AFT_AIGC,
  //     url: `/aigc_xhs_note_feedback`,
  //     method: 'post',
  //     data,
  //   });
  // },
  newAigcNoteFeedback(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/note/feedback`,
      method: 'post',
      data,
    });
  },
  getAigcNoteList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/notes`,
      method: 'post',
      data,
      params: {
        page: data.page,
        size: data.size,
      },
    });
  },
  getAigcNoteDetail(params) {
    return request({
      baseURL: AFT_AIGC,
      url: `/aigc_xhs_notes/${params.note_id}`,
      method: 'get',
    });
  },
  //   updateAigcNote(data) {
  //   return request({
  //     baseURL: AFT_AIGC,
  //     url: `/aigc_xhs_note/${data.note_id}`,
  //     method: 'post',
  //     data,
  //   });
  // },
  updateAigcNote(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/note/${data.noteId}`,
      method: 'post',
      data,
    });
  },
  // 卖点下拉列表
  getAigcDictsList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/aigc/dicts',
      method: 'POST',
      data,
      params: {
        page: data.page,
        size: data.size,
      },
    });
  },
  updateAigcDict(data) {
    return request({
      baseURL: AFT_AIGC,
      url: `/aigc_dict/${data.dict_id}`,
      method: 'post',
      data,
    });
  },
  newAigcDict(data) {
    return request({
      baseURL: AFT_AIGC,
      url: `/aigc_dict`,
      method: 'post',
      data,
    });
  },
  getAigcPromptsList(data) {
    return request({
      baseURL: AFT_AIGC,
      url: `/aigc_xhs_prompts`,
      method: 'post',
      data,
      params: {
        page: data.page,
        size: data.size,
      },
    });
  },
  updateAigcPrompt(data) {
    return request({
      baseURL: AFT_AIGC,
      url: `/aigc_xhs_prompt/${data.prompt_id}`,
      method: 'post',
      data,
    });
  },
  newAigcPrompt(data) {
    return request({
      baseURL: AFT_AIGC,
      url: `/aigc_xhs_prompt`,
      method: 'post',
      data,
    });
  },
  getBrandSeries() {
    return request({
      baseURL: AFT_AIGC,
      url: `/aigc_support_brand_series`,
      method: 'get',
      params: {
        page: 1,
        size: 100,
      },
    });
  },

  // 图片库
  // 品牌列表
  getBrandList() {
    return request({
      baseURL: BFZ_BFF,
      url: '/matrix/manager/picture/lib/brand/optional',
      method: 'get',
    });
  },
  // 车系列表
  getTypeList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/matrix/manager/picture/lib/series/optional',
      method: 'get',
      params,
    });
  },
  // 标签列表
  getTagList() {
    return request({
      baseURL: BFZ_BFF,
      url: '/matrix/manager/picture/lib/tags',
      method: 'get',
    });
  },
  // 获取图片列表
  getPicList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/matrix/manager/picture/lib/picture/page',
      method: 'get',
      params,
    });
  },
  // 上传图片
  addLibraryPic(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/matrix/manager/picture/lib/picture/add',
      method: 'post',
      data,
    });
  },
  // 添加标签
  handleAddTag(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/matrix/manager/picture/lib/add/tag',
      method: 'post',
      data,
    });
  },
  // 编辑标签
  updateTagApi(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/matrix/manager/picture/lib/update/tag',
      method: 'post',
      data,
    });
  },

  // 媒体号列表
  // 列表查询
  getAgencyList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/author/select/page',
      method: 'get',
      params,
    });
  },
  // 添加媒体号
  handleAddMedia(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/author/add',
      method: 'post',
      data,
    });
  },
  // 查询经销商信息
  getDealerDetail(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/author/get/dealer',
      method: 'get',
      params,
    });
  },
  // 批量取消代运营
  handleDeleteMedia(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/author/delete',
      method: 'DELETE',
      data,
    });
  },
  // 获取小红书cookies
  getXHSCookies(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/author/get/cookie',
      method: 'get',
      params,
    });
  },
  // 记录编辑图片
  handleEditRecord(fileId) {
    return request({
      baseURL: BFZ_BFF,
      url: `/matrix/manager/picture/lib/file/editor?fileId=${fileId}`,
      method: 'post',
    });
  },

  // 文案编辑页
  // 人设列表
  getCustomerTypeList(data) {
    return request({
      baseURL: '/bfz-bff',
      url: '/xhs/aigc/target',
      method: 'post',
      data,
    });
  },
  // 三级(新)
  getThirdSellingList(data) {
    return request({
      baseURL: '/bfz-bff',
      url: '/xhs/aigc/getThirdFeatureGroup',
      method: 'post',
      data,
    });
  },
  // 生成故事
  getNewAiStory(data) {
    return request({
      baseURL: '/bfz-bff',
      url: '/xhs/aigc/makeStory',
      method: 'post',
      data,
    });
  },
  // 生成仿写小红书文案
  getNewContent(data) {
    return request({
      baseURL: '/bfz-bff',
      url: '/xhs/aigc/makeNote',
      method: 'post',
      data,
    });
  },
  // 加入Emoji
  getEmojiContent(data) {
    return request({
      baseURL: '/bfz-bff',
      url: '/xhs/aigc/makeEmoji',
      method: 'post',
      data,
    });
  },
  // 生成标题
  getContentTitle(data) {
    return request({
      baseURL: '/bfz-bff',
      url: '/xhs/aigc/makeTitle/v1',
      method: 'post',
      data,
    });
  },
  // 生成话题
  getHashTagContent(data) {
    return request({
      baseURL: '/bfz-bff',
      url: '/xhs/copyWriting/aiXhsTopicList',
      method: 'post',
      data,
    });
  },
  // 自动分行
  automaticLineWrapping(data) {
    return request({
      baseURL: '/bfz-bff',
      url: '/xhs/aigc/automaticLineWrapping',
      method: 'post',
      data,
    });
  },
  // 手动搜索话题
  getHashTagSearch(data) {
    return request({
      baseURL: '/bfz-bff',
      url: '/xhs/copyWriting/xhsTopicList',
      method: 'post',
      data,
    });
  },
  // 查询文案列表
  getCopyWrittingList(data) {
    return request({
      baseURL: '/bfz-bff',
      url: '/xhs/copyWriting/page',
      method: 'post',
      data,
    });
  },
  // 查询地点
  getPublishLocation(params) {
    return request({
      baseURL: '/bfz-bff',
      url: '/xhs/copyWriting/postPositionList',
      method: 'get',
      params,
    });
  },
  // 保存文章
  handleSaveCopywriting(data) {
    return request({
      baseURL: '/bfz-bff',
      url: '/xhs/copyWriting/insertOrUpdate',
      method: 'post',
      data,
    });
  },
  // 发布文章
  handleCopywrittingPost(data) {
    return request({
      baseURL: '/bfz-bff',
      url: '/xhs/copyWriting/savePostNoteSetting',
      method: 'post',
      data,
    });
  },
  // 获取文案详情
  getCopywrittingDetail(id) {
    return request({
      baseURL: '/bfz-bff',
      url: `/xhs/copyWriting/findById/${id}`,
      method: 'get',
    });
  },
  // 文章删除
  handleCopywrittingDel(id) {
    return request({
      baseURL: '/bfz-bff',
      url: `/xhs/copyWriting/deleteById/${id}`,
      method: 'DELETE',
    });
  },
  // 取消记录编辑图片
  handleCancelEditRecord(fileId) {
    return request({
      baseURL: BFZ_BFF,
      url: `/matrix/manager/picture/lib/file/cancelEditor?fileId=${fileId}`,
      method: 'post',
    });
  },
  // 删除标签
  deleteTag(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/matrix/manager/picture/lib/cmsFileTag/delete/${id}`,
      method: 'DELETE',
    });
  },
  // 根据id获取已使用笔记列表
  handleListNoteByFileId(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/copyWriting/listNoteByFileId`,
      method: 'get',
      params,
    });
  },
  // 通过文件id列表删除文件列表
  handleDeletePicture(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/matrix/manager/picture/lib/file/delete`,
      method: 'post',
      data,
    });
  },
  // 获取所有省份
  getLevelArea() {
    return request({
      baseURL: '/base',
      url: `/areas/v1?level=1`,
      method: 'get',
    });
  },
  // 编辑文件标签
  handleUpdateFileTag(fileId, data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/matrix/manager/picture/lib/file/modifyTags/${fileId}`,
      method: 'post',
      data,
    });
  },
  // 获取小红书主页链接
  getXhsAccountPage(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/author/get/user/profile',
      method: 'get',
      params,
    });
  },
  // 获取媒体号创建人列表
  getMediaCreatorList() {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/author/listCreator',
      method: 'get',
    });
  },
  // 获取文案列表创建人列表
  getCopywrittingCreatorList() {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/copyWriting/listCreator',
      method: 'get',
    });
  },
  // 获取图片列表创建人列表
  getPictureCreatorList() {
    return request({
      baseURL: BFZ_BFF,
      url: '/matrix/manager/picture/lib/listCreator',
      method: 'get',
    });
  },
  // 获取选题列表创建人列表
  getTopicCreatorList() {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/topicSelection/listCreator',
      method: 'get',
    });
  },
  // 获取模板创建人列表
  getTemplateCreatorList() {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/copywritingTemplate/creatorList',
      method: 'get',
    });
  },
  // ai质量评分
  handleAiRate(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/aiContent/insertOrUpdate',
      method: 'post',
      data,
    });
  },
  // 获取仿写文案-模版
  handleGetCopywriting(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/aigc/inputNote',
      method: 'post',
      data,
    });
  },
  // 获取仿写文案-自由
  handleGetFreeCopywriting(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/aigc/dialogueNote',
      method: 'post',
      data,
    });
  },

  //AI作图
  //新增或更新主题基本信息
  saveSubjectBaseInfo(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/aiImage/saveSubjectBaseInfo',
      method: 'post',
      data,
    });
  },
  //获取主题列表
  getPageSubject(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/aiImage/pageSubject',
      method: 'get',
      params,
    });
  },
  //通过主题id删除主题
  deleteSubject(subjectId) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/deleteBySubjectId/${subjectId}`,
      method: 'delete',
    });
  },
  //保存主题的配置信息
  saveSubjectConfig(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/aiImage/saveSubjectConfigParam',
      method: 'post',
      data,
    });
  },
  //图生图 - 生成
  generateImg(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/aiImage/img2img',
      method: 'post',
      data,
    });
  },
  //保存图片库
  saveImageLibrary(data) {
    return request({
      baseURL: AFT_CONTENT,
      url: '/v1/cms/images/saveImageLibrary',
      method: 'post',
      data,
    });
  },
  //图生图-车换背景的配置参数
  getCarOptions() {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/aiImage/generate/car-bg/options',
      method: 'get',
    });
  },
  //图生图-只换模特的配置参数
  getModelOptions() {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/aiImage/generate/model/options',
      method: 'get',
    });
  },
  //文生图-车的配置参数
  getCarText2ImgOptions() {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/aiImage/carText2ImgOptions',
      method: 'get',
    });
  },
  //获取详情
  getSubjectDetail(subjectId) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/getSubjectDetailById/${subjectId}`,
      method: 'get',
    });
  },
  //图片url转base64
  imageDto(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/copyWriting/imageDto`,
      method: 'get',
      params,
    });
  },
  //跳转图文编辑器前，保存所选图片链接，有效期30分钟，结果返回跳转的id
  saveImageList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/graphic-text-template/before-jump-editor',
      method: 'post',
      data,
    });
  },
  // 获取临存数据
  getTempPicList(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/graphic-text-template/jump-editor/${id}`,
      method: 'GET',
    });
  },
  //提交图生图任务
  submitTaskForImg2img(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/aiImage/submitTaskForImg2img',
      method: 'post',
      data,
    });
  },
  //查询执行中和排队中的任务列表
  runningAndLineTaskList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/runningAndLineTaskList`,
      method: 'get',
      params,
    });
  },
  //分页查询任务列表，默认只查询已成功和已失败的任务列表
  getPageTask(params, { startTime, endTime }) {
    // startTime, endTime 放进params会被
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/pageTask?startTime=${startTime}&endTime=${endTime}`,
      method: 'get',
      params,
    });
  },
  //分页查询AI产出的图片列表
  pageAiImageOutput(params, { startTime, endTime }) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/pageAiImageOutput?startTime=${startTime}&endTime=${endTime}`,
      method: 'get',
      params,
    });
  },
  //隐藏任务
  hideTask(taskId) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/hideTask/${taskId}`,
      method: 'put',
    });
  },
  //通过任务id获取任务的详细信息
  getTaskDetailById(taskId) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/getTaskDetailById/${taskId}`,
      method: 'get',
    });
  },
  //废弃任务，只有排队中的任务才允许废弃
  discardTask(taskId) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/discardTask/${taskId}`,
      method: 'put',
    });
  },
  //批量更新任务的队列顺序
  batchUpdateTaskScore(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/batchUpdateTaskScore`,
      method: 'put',
      data,
    });
  },
  //批量删除ai作图产出的图片
  batchDeleteAiImageOutputByIds(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/batchDeleteAiImageOutputByIds`,
      method: 'delete',
      data,
    });
  },
  //更新任务
  updateTaskForImg2img(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/updateTaskForImg2img`,
      method: 'put',
      data,
    });
  },
  //重新提交任务
  resubmitTask(taskId) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/resubmitTask/${taskId}`,
      method: 'post',
    });
  },
  // 通过任务id获取图片产出，失败的任务为空列表
  getOutputListByTaskIdApi(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/getOutputListByTaskId/${params.taskId}`,
      method: 'get',
    });
  },

  // 获取标签树
  getTagTree() {
    return request({
      baseURL: AFT_CONTENT,
      url: '/cmsFileTag/tree',
      method: 'get',
    });
  },

  // 重命名标签树节点
  renameTagTreeNode(data) {
    return request({
      baseURL: AFT_CONTENT,
      url: `/cmsFileTag/tree/node`,
      method: 'put',
      data,
    });
  },

  // 添加同级标签/子标签
  addTagTreeNode(data) {
    return request({
      baseURL: AFT_CONTENT,
      url: `/cmsFileTag/tree/node`,
      method: 'post',
      data,
    });
  },

  // 删除标签
  delTagTreeNode(id) {
    return request({
      baseURL: AFT_CONTENT,
      url: `/cmsFileTag/tree/node/${id}`,
      method: 'delete',
    });
  },

  // 编辑人设信息
  editPersonality(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/manager/xhs/author/editPersonality`,
      method: 'put',
      data,
    });
  },

  // 获取选题标签树
  getTopicTagTree(parentId) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelectionTag/listByParentId/${parentId}`,
      method: 'get',
    });
  },
  // 新增子标签
  addTopicChildTag(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelectionTag`,
      method: 'POST',
      data,
    });
  },
  // 编辑标签
  editTopicTag(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelectionTag`,
      method: 'PUT',
      data,
    });
  },
  // 删除标签
  deleteTopicTag(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelectionTag/${id}`,
      method: 'DELETE',
    });
  },
  // 获取选题列表
  getTopicList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelection/page`,
      method: 'post',
      data,
    });
  },
  // 推送图片到选题列表
  pushImgToTopic(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelection/insertImageList`,
      method: 'POST',
      data,
    });
  },
  // 推送笔记到选题列表
  pushNoteToTopic(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelection/insertNoteList`,
      method: 'POST',
      data,
    });
  },
  // 通过笔记id/链接获取笔记列表
  getNoteListByIdOrLink(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelection/queryNoteList`,
      method: 'POST',
      data,
    });
  },
  // 通过id列表删除选题
  deleteTopicByIdList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelection/deleteByIdList`,
      method: 'DELETE',
      data,
    });
  },
  // 修改选题
  updateTopic(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelection/batchUpdateTagList`,
      method: 'PUT',
      data,
    });
  },
  // 选题库id获取笔记详情
  getNoteDetailByTopicId(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelection/getNoteDetailById/${id}`,
      method: 'GET',
    });
  },

  // 查询一级卖点
  getFeatureCodeList() {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aigc/feature_code_list`,
      method: 'get',
    });
  },

  // 分页查询卖点列表(新)
  getFeatureInfoList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aigc/page/feature_info`,
      method: 'post',
      data,
    });
  },

  // 查询笔记创建人列表
  getNoteCreatorList() {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/note/listCreator`,
      method: 'get',
    });
  },

  // 查询笔记详情
  getNoteDetailById(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/note/getDetailById/${id}`,
      method: 'get',
    });
  },

  // 删除笔记
  handleNoteDel(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/note/deleteByIdList`,
      method: 'DELETE',
      data,
    });
  },

  // 通过ID/链接查询笔记
  getOriginXhsNote(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelection/queryNoteList`,
      method: 'post',
      data,
    });
  },

  // 推送笔记到选题库
  handlePushNote(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelection/insertNoteList`,
      method: 'post',
      data,
    });
  },

  // 新增采集任务
  addCollectTask(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/crawlTask/insertOrUpdate`,
      method: 'post',
      data,
    });
  },
  // 搜索采集任务
  getCollectTaskList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/crawlTask/page`,
      method: 'get',
      params,
    });
  },
  // 删除采集任务
  handleTaskDel(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/crawlTask/deleteById/${id}`,
      method: 'DELETE',
    });
  },
  // 查询采集任务
  getCollectTaskDetail(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/crawlTask/findById/${id}`,
      method: 'get',
    });
  },

  // 查询知识库列表
  getKnowledgeList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/knowledgeBase/page`,
      method: 'post',
      data,
    });
  },

  // 删除知识库信息
  handleKnowledgeDel(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/knowledgeBase/deleteByIdList`,
      method: 'DELETE',
      data,
    });
  },

  // 知识库id查询笔记详情
  getNoteDetailByKnowledgeId(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/knowledgeBase/getDetailById/${id}`,
      method: 'GET',
    });
  },

  // 导出采集任务
  downloadTaskDetail(params) {
    return request({
      baseURL: BFZ_BFF,
      responseType: 'blob',
      url: `/xhs/crawlTask/list/export`,
      method: 'GET',
      params,
    });
  },

  // 获取自定义ai故事
  getDiyAiStory(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aigc/commonAiRewrite`,
      method: 'post',
      data,
    });
  },

  // 查询现有负责人列表
  getCurrentManagerList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/author/listMasterAccountUsername',
      method: 'get',
      params,
    });
  },

  // 查询全部负责人列表
  getManagerList(params) {
    return request({
      baseURL: casModule,
      url: `/ums/users`,
      method: 'get',
      params,
    });
  },

  // 编辑账号负责人
  editAccountManager(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/manager/xhs/author/editMasterAccount`,
      method: 'put',
      data,
    });
  },

  // 运营计划
  // 查询媒体号列表
  getPlanMediaList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/notePublishPlan/pageAuthor',
      method: 'get',
      params,
    });
  },

  // 添加计划媒体号
  handleAddPlanMedia(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/notePublishPlan/addAuthorList`,
      method: 'post',
      data,
    });
  },

  // 运营计划列表数据
  getPlanDataList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/notePublishPlan/planList`,
      method: 'post',
      data,
    });
  },

  // 修改计划表数据
  handlePlanEdit(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/notePublishPlan/insertOrUpdatePlan`,
      method: 'post',
      data,
    });
  },

  // 导出发布计划
  downloadPlanList(data) {
    return request({
      baseURL: BFZ_BFF,
      responseType: 'blob',
      url: `/xhs/notePublishPlan/exportPublishPlan`,
      method: 'post',
      data,
    });
  },

  // 批量粘贴
  handlePlanBatchPaste(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/notePublishPlan/copyPlan`,
      method: 'post',
      data,
    });
  },

  // 删除媒体号
  handlePlanMediaDel(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/notePublishPlan/deleteByXhsNotePublishAuthorId/${id}`,
      method: 'delete',
    });
  },

  // 运营执行
  // 运营执行列表
  getPlanExecuteList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/notePublishPlan/pagePlan',
      method: 'get',
      params,
    });
  },

  // 发布状态统计
  getPlanStatusList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/notePublishPlan/planPublishStatusStat',
      method: 'get',
      params,
    });
  },

  // 更新发布计划
  editPlanExecute(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/notePublishPlan/updateExecutePlan`,
      method: 'put',
      data,
    });
  },

  // 检测敏感词
  noteCheckApi(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/noteCheck/greenCheck`,
      method: 'post',
      data,
    });
  },

  // 获取计划详情
  getPlanDetail(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/notePublishPlan/getAuthorPublishPlanDetail`,
      method: 'GET',
      params,
    });
  },
  // 运营计划-查询历史记录
  getPlanHistoryList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/notePublishPlan/pageLogPlan`,
      method: 'POST',
      data,
    });
  },
  // 查询执行计划笔记详情
  getNoteDetailByPlanId(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/note/getDetailByNoteId',
      method: 'get',
      params,
    });
  },

  // 获取选题随机文案模板
  getRandomTemplateList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/copywritingTemplate/randomList',
      method: 'get',
      params,
    });
  },

  // 获取选题随机笔记
  getRandomNoteList(params, topicSelectionType) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelection/randomList?topicSelectionType=${topicSelectionType}`,
      method: 'get',
      params,
    });
  },

  // 获取媒体号详情
  getMediaDetailById(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/preGenerate/getAuthorInfo',
      method: 'get',
      params,
    });
  },

  // 查询计划基础信息
  getDetailByPlanId(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/preGenerate/getBaseInfo',
      method: 'get',
      params,
    });
  },

  // 查询计划预生产资料
  getPreNoteByPlanId(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/preGenerate/listByPlanId',
      method: 'get',
      params,
    });
  },

  // 查询计划预生产图片
  getPrePicByPlanId(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/preCopywritingImages/listByPlanId',
      method: 'get',
      params,
    });
  },

  // 编辑页发布笔记
  publishNoteFormDraft(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/preGenerate/savePostNoteSetting`,
      method: 'POST',
      data,
    });
  },

  // 重新生产预生产文案
  handleRedoPreNote(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/preGenerate/reGenerate`,
      method: 'get',
      params,
    });
  },

  // 推送至成员
  handlePushToMember(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/note/push/member`,
      method: 'POST',
      data,
    });
  },

  // 推送至链接
  handlePushToLink(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/note/push/sharePublishLink`,
      method: 'POST',
      data,
    });
  },

  // 获取已推送笔记详情
  getNoteDetailByPushId(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/note/push/detail`,
      method: 'get',
      params,
    });
  },

  // aigc工具列表
  getToolTypeList() {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/rewriteTool/list`,
      method: 'get',
    });
  },

  // 新-AI改写
  getAiRewriteStory(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/rewriteTool/run`,
      method: 'POST',
      data,
    });
  },

  // 笔记推送-填充笔记链接
  handleUpdatePushLink(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/note/push/fillNoteLink`,
      method: 'put',
      data,
    });
  },

  // 再来一篇-生成ai预生产文案
  handleGetAiPre(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/preGenerate/generate`,
      method: 'get',
      params,
    });
  },

  // 存入我的图片库列表
  handleBatchToMyPicStore(data) {
    return request({
      baseURL: AFT_CONTENT,
      url: `/design/userImages/save`,
      method: 'post',
      data,
    });
  },
  // 查询选题统计信息
  getTopicTypeTotal(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelection/topicSelectionStat`,
      method: 'POST',
      data,
    });
  },

  // 批量删除选题库数据
  handleTopicBatchDel(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/topicSelection/batchDelete',
      method: 'DELETE',
      data,
    });
  },

  // 批量更新选题库品牌车系
  handleTopicBatchUpdateBrand(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelection/batchUpdateCarInfo`,
      method: 'put',
      data,
    });
  },

  // 自动化生产配置
  // 查询自动生产配置列表
  getAutoProductList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelectionTag/listProductionConfig`,
      method: 'GET',
      params,
    });
  },

  // 文案数量检测
  getProduceCheckList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelectionTag/list`,
      method: 'GET',
      params,
    });
  },

  // 更新文案生产配置
  updateAutoConfig(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelectionTag`,
      method: 'put',
      data,
    });
  },

  // 更新文案图片生产状态
  switchImgAutoStatus(id, status) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelectionTag/changeImgStatus/${id}?status=${status}`,
      method: 'POST',
    });
  },

  // 更新文案生产状态
  switchAutoStatus(id, status) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topicSelectionTag/changeStatus/${id}?status=${status}`,
      method: 'POST',
    });
  },

  // 起号品牌列表
  getBrandConfigList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/authorGenerateConfig/page`,
      method: 'POST',
      data,
    });
  },

  // 新增起号品牌配置
  addBrandConfig(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/authorGenerateConfig/add`,
      method: 'POST',
      data,
    });
  },

  // 删除起号品牌配置
  delBrandConfig(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/authorGenerateConfig/delete?id=${id}`,
      method: 'POST',
    });
  },

  // 我的图库列表
  getMyPicStoreList(params) {
    return request({
      baseURL: AFT_CONTENT,
      url: '/design/userImages/page',
      method: 'get',
      params,
    });
  },

  // 获取门店列表
  getStoreList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/matrix/member/pageList`,
      method: 'GET',
      params,
    });
  },

  // 获取账号列表
  getAccountList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/matrix/author/pageList`,
      method: 'GET',
      params,
    });
  },

  // 新建媒体分组
  addMediaGroup(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/manager/matrixGroup/create`,
      method: 'POST',
      data,
    });
  },

  // 删除媒体分组
  deleteMediaGroup(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/manager/matrixGroup/delete`,
      method: 'GET',
      params,
    });
  },

  // 媒体分组列表
  getMediaGroupList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/manager/matrixGroup/page`,
      method: 'GET',
      params,
    });
  },

  // 我的媒体分组列表
  getMyMediaGroupList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/manager/matrixGroup/list`,
      method: 'GET',
      params,
    });
  },

  // 获取分组详情
  getGroupDetail(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/manager/matrixGroup`,
      method: 'GET',
      params,
    });
  },

  // 获取分组媒体号列表
  getGroupMediaList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/manager/matrixGroup/detail/page`,
      method: 'GET',
      params,
    });
  },

  // 更新分组信息
  editGroupDetail(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/manager/matrixGroup/update`,
      method: 'put',
      data,
    });
  },

  // 媒体号加入分组
  patchMediaInGroup(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/manager/matrixGroup/join`,
      method: 'POST',
      data,
    });
  },

  // 分组删除媒体号
  removeMeidaFromGroup(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/manager/matrixGroup/detail/delete`,
      method: 'GET',
      params,
    });
  },

  // 小红书计划模板
  getPlanTemplateList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/planTemplate/page`,
      method: 'GET',
      params,
    });
  },

  // 新增/编辑计划模板
  updatePlanTemplate(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/planTemplate`,
      method: 'POST',
      data,
    });
  },

  // 制作计划模板
  makePlanTemplate(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/planTemplate/makeTemplate`,
      method: 'POST',
      data,
    });
  },

  // 删除计划模板
  delPlanTemplate(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/planTemplate/${id}`,
      method: 'DELETE',
    });
  },

  // 获取计划模板信息
  getPlanTemplateDetail(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/planTemplate/${id}`,
      method: 'GET',
    });
  },

  // 应用计划模板
  usePlanTemplate(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/planTemplate/applyTemplate`,
      method: 'POST',
      data,
    });
  },

  // 通过任务id获取失败的图片产出列表
  getFailImgList(taskId) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/failedImageList/${taskId}`,
      method: 'GET',
    });
  },

  // 知识库创建人列表
  getKnowledgeCreatorList() {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/knowledgeBase/listCreator`,
      method: 'GET',
    });
  },

  // 通过车系id和城市编码信息查询车型信息
  getCarInfoByCity(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/api/xhs/series/specInfo`,
      method: 'GET',
      params,
    });
  },

  // ai改图的配置参数
  getAiPicSetting() {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/carImgByImgOptions`,
      method: 'GET',
    });
  },

  // 获取小红书授权账号列表
  getXhsAuthorizeList() {
    return request({
      baseURL: BFZ_BFF,
      url: `/manager/xhs/author/listBackendAuthor`,
      method: 'GET',
    });
  },

  // 获取小红书本地话题
  getXhsLocalTopicList(keyword) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/topic/listByKeyword?keyword=${keyword}`,
      method: 'GET',
    });
  },

  // 知识库待处理笔记
  getPendingNoteNum() {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/knowledgeBase/countPendingNote`,
      method: 'GET',
    });
  },

  // 忽略知识库笔记
  handleIgnoreNote(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/knowledgeBase/ignoreByIdList`,
      method: 'post',
      data,
    });
  },

  // AI作图任务日志列表
  getAiImageTaskLogList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/pageTaskV1`,
      method: 'post',
      data,
    });
  },

  // AI作图创建人列表
  getAiImageCreatorList() {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/aiImage/listAiImageCreator`,
      method: 'GET',
    });
  },

  // AI作视频提交任务
  createAiVideoTask(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/masterAiTask/submitTask`,
      method: 'post',
      data,
    });
  },

  // AI视频日志
  getAiVideoTaskLogList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/masterAiTask/page`,
      method: 'get',
      params,
    });
  },

  // 获取视频任务详情
  getAiVideoTaskDetail(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/masterAiTask/findById/${id}`,
      method: 'get',
    });
  },

  // 视频任务创建人列表
  getAiVideoTaskCreatorList() {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/masterAiTask/listCreator`,
      method: 'get',
    });
  },

  // 获取AI作视频材料列表
  getAiVideoMaterialList() {
    return request({
      baseURL: '/ai-material-video',
      url: `/api/v1/parameters`,
      method: 'get',
    });
  },

  // 获取AI视频地址
  getAiVideoUrl(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/masterAiTask/getAiVideoUrl/${id}`,
      method: 'get',
    });
  },

  // 更改任务名称
  updateVideoTaskName(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/masterAiTask/updateTaskName`,
      method: 'put',
      data,
    });
  },

  // 再次执行视频任务
  repeatVideoTask(sourceId) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/masterAiTask/resubmitTask/${sourceId}`,
      method: 'post',
    });
  },

  // 小红书车系卖点列表
  getXhsCarSeriesSellPointList(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsSellingPointInfo/page`,
      method: 'get',
      params,
    });
  },

  // 小红书卖点车系列表
  getXhsSellPointCarSeriesList(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsSellingPointInfo/seriesList`,
      method: 'get',
      params,
    });
  },

  // 生产AI作文
  createAiCompositionTask(data) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsAiContent/generate`,
      method: 'post',
      data,
    });
  },

  // 记录AI作文ID
  chooseAiContent(id) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsAiContent/chooseAiContent/${id}`,
      method: 'put',
    });
  },

  // 查询优惠活动信息
  getXhsActivityInfoList(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsPromotionInfo/page`,
      method: 'get',
      params,
    });
  },

  // 运营计划添加备注
  addXhsPromotionInfoRemark(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/notePublishPlan/updateTopic`,
      method: 'put',
      data,
    });
  },

  // 删除推送记录
  deleteXhsPromotionInfoRemark(id, params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/note/push/${id}`,
      method: 'delete',
      params,
    });
  },

  // 获取AI作文文章结构
  getAiContentArticleStructure(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsArticleStructureTagConfig/all`,
      method: 'get',
      params,
    });
  },

  // 获取AI作文适用场景列表
  getAiContentApplicableSceneList() {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsCarApplicableScene/all`,
      method: 'get',
    });
  },

  // 产品列表(新-车系列表)
  getNewTypeList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/matrix/manager/picture/lib/series/optional/v1',
      method: 'get',
      params,
    });
  },

  // 重新生产预生产图片
  handleRedoPrePic(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/preCopywritingImages/reGenerate`,
      method: 'get',
      params,
    });
  },

  // 获取车系推荐卖点
  getSuggestSellPointList(data) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: '/sysAdmin/xhsSellingPointInfo/embedding/relevantSearch/v1',
      method: 'post',
      data,
    });
  },

  // AI识别图片
  recognizeAiPic(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/aigc/image/understanding`,
      method: 'get',
      params,
    });
  },

  // 获取营销节日
  getSellHolidayList() {
    return request({
      baseURL: '/operation-efficiency-up',
      url: '/sysAdmin/xhsMarketingCalendar/findFutureMarketingDate',
      method: 'get',
    });
  },

  // 获取车系成员列表
  getCarSeriesMemberList(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: '/sysAdmin/xhsPromotionInfo/memberList',
      method: 'get',
      params,
    });
  },

  // AI营销润色
  aiMarketingRefinement(data) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: '/sysAdmin/xhsAiContent/marketingPrompt',
      method: 'post',
      data,
    });
  },

  // 获取小红书文风列表
  getWritingStyleList() {
    return request({
      baseURL: '/operation-efficiency-up',
      url: '/sysAdmin/xhsWritingStyleConfig/all',
      method: 'get',
    });
  },

  // 生成结构作文
  generateStructureTag(data) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: '/sysAdmin/xhsAiContent/generateWritingStyle',
      method: 'post',
      data,
    });
  },

  // 新增/编辑卖点
  updateSellPoint(data) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: '/sysAdmin/xhsSellingPointInfo',
      method: 'post',
      data,
    });
  },

  // 删除卖点
  deleteSellPoint(id) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsSellingPointInfo/${id}`,
      method: 'delete',
    });
  },

  // 获取爆炸词列表
  getHitWordList(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: '/sysAdmin/xhsWord/page',
      method: 'get',
      params,
    });
  },

  // 删除爆炸词
  deleteHitWord(id) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsWord/${id}`,
      method: 'delete',
    });
  },

  // 新增爆炸词
  addHitWord(data) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: '/sysAdmin/xhsWord',
      method: 'post',
      data,
    });
  },

  // 添加/修改活动
  addOrUpdateActivity(data) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: '/sysAdmin/xhsPromotionInfo',
      method: 'post',
      data,
    });
  },

  // 删除活动
  deleteActivity(id) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsPromotionInfo/${id}`,
      method: 'delete',
    });
  },

  // 切换活动上下架
  changeActivityStatus(data) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsPromotionInfo/changeShelve`,
      method: 'put',
      data,
    });
  },

  // 获取活动城市
  getCityList(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: '/sysAdmin/xhsPromotionInfo/cityList',
      method: 'get',
      params,
    });
  },

  //获取卖点信息
  getSellPointInfo(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: '/sysAdmin/xhsSellingPointInfo/statistic',
      method: 'get',
      params,
    });
  },

  // 分页查询小红书成员
  getMemberList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/member/page',
      method: 'post',
      data,
    });
  },

  // 添加成员
  handleAddMember(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/member',
      method: 'post',
      data,
    });
  },

  // 修改运营状态
  changeMemberStatus(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/member',
      method: 'put',
      data,
    });
  },

  // 查询成员运营列表
  getMemberOperateList() {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/member/operatorList',
      method: 'get',
    });
  },

  // 获取成员统计信息
  getMemberStatistics(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/member/memberStatistic',
      method: 'post',
      data,
    });
  },

  // 获取品牌管理列表
  getBrandManageList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/principal/page',
      method: 'get',
      params,
    });
  },

  // 更新品牌状态
  changeBrandStatus(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/principal',
      method: 'put',
      data,
    });
  },

  // 添加管理品牌
  handleAddBrand(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/principal',
      method: 'post',
      data,
    });
  },

  // 查询小红书人员
  getTeamMember(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/operator/page',
      method: 'get',
      params,
    });
  },

  // 获取百夫长用户列表
  getAllUserList() {
    return request({
      baseURL: '/bfz-dy',
      url: '/auth/users?page_no=1&page_size=999',
      method: 'get',
    });
  },

  // 获取团队管理列表
  getTeamManageList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/operatorGroup/page',
      method: 'get',
      params,
    });
  },

  // 添加团队
  handleAddTeam(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/operatorGroup',
      method: 'post',
      data,
    });
  },

  // 删除团队
  handleDelTeam(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/manager/xhs/operatorGroup/${id}`,
      method: 'delete',
    });
  },

  // 添加运营人员
  handleAddOperator(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/operator/batchAdd',
      method: 'post',
      data,
    });
  },

  // 人员变更团队
  changeOperatorTeam(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/operator/updateGroup',
      method: 'put',
      data,
    });
  },

  // 删除运营人员
  handleDelOperator(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/manager/xhs/operator/${id}`,
      method: 'delete',
    });
  },

  // 获取资源列表
  getSourceList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/member/listByOperatorUsername',
      method: 'get',
      params,
    });
  },

  // 资源转移
  handleTransferResource(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/operator/transferResource',
      method: 'put',
      data,
    });
  },

  // 批量切换代运营状态
  handleAccountSwitch(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/author/batchOperator',
      method: 'PUT',
      data,
    });
  },

  // 获取账号统计
  getAccountStatistics(params) {
    return request({
      baseURL: BFZ_BFF,
      url: '/manager/xhs/author/authorStatistic',
      method: 'get',
      params,
    });
  },

  // 获取推送数据统计
  getPushDataStatistics(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/note/push/statistic',
      method: 'post',
      data,
    });
  },

  // 媒体号列表导出
  exportMediaList(params) {
    return request({
      baseURL: BFZ_BFF,
      responseType: 'blob',
      url: `/manager/xhs/author/select/exportList`,
      method: 'get',
      params,
    });
  },

  // 查询任务列表
  getTaskList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/task/page',
      method: 'post',
      data,
    });
  },

  // 新增任务
  handleAddTask(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/task',
      method: 'post',
      data,
    });
  },

  // 更新任务
  handleUpdateTask(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/task',
      method: 'put',
      data,
    });
  },

  // 删除任务
  handleDelTask(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/task/${id}`,
      method: 'delete',
    });
  },

  // 任务详情
  getTaskDetail(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/task/${id}`,
      method: 'get',
    });
  },

  // 任务达成情况
  getTaskAchievement(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/task/reviewDetail/${id}`,
      method: 'get',
    });
  },

  // 任务审核列表
  getTaskReviewList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: '/xhs/taskSubmitMaterial/listStat',
      method: 'post',
      data,
    });
  },

  // 任务审核详情
  getTaskReviewDetailList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/taskSubmitMaterial/list`,
      method: 'post',
      data,
    });
  },

  // 任务审核结果
  getTaskResult(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/taskSubmitMaterial/approveStat`,
      method: 'post',
      data,
    });
  },

  // 任务审核
  handleTaskReview(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/taskSubmitMaterial/approve`,
      method: 'post',
      data,
    });
  },

  // 笔记分析列表(新)
  getNoteAnalysisList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/v2/xhs/note/noteAnalysePage`,
      method: 'post',
      data,
    });
  },

  // 笔记分析导出(新)
  exportNoteAnalysis(data) {
    return request({
      baseURL: BFZ_BFF,
      responseType: 'blob',
      url: `/v2/xhs/note/exportNote`,
      method: 'post',
      data,
    });
  },

  // 任务达成情况
  getTaskAchievement(id) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/task/reviewDetail/${id}`,
      method: 'get',
    });
  },

  // 检测结果查询
  getPushResultList(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/notePushDetectResult/page`,
      method: 'post',
      data,
    });
  },

  // 检查推送检测结果
  checkPushResult() {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/notePushDetectResult/newRecordCount`,
      method: 'get',
    });
  },

  // 生成AI干货文章
  generateAiArticle(data) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: '/sysAdmin/xhsAiContent/rewrite',
      method: 'post',
      data,
    });
  },

  // 获取笔记历史
  getNoteOperatorHistory(params) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsOperatorLog`,
      method: 'get',
      params,
    });
  },

  // 获取笔记历史详情
  getNoteDetailHistoryId(id) {
    return request({
      baseURL: '/operation-efficiency-up',
      url: `/sysAdmin/xhsOperatorLog/${id}`,
      method: 'get',
    });
  },

  // 获取推荐草稿
  getRecommendDraft(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/recommend/generate`,
      method: 'post',
      data,
    });
  },

  // 记录推荐复制日志
  handleRecommendCopy(generateLogId) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/recommend/copy?generateLogId=${generateLogId}`,
      method: 'post',
    });
  },

  // 计划选题拖拽
  handleDragTag(data) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/notePublishPlan/movePlan`,
      method: 'post',
      data,
    });
  },

  // 查询发布计划
  getPublishPlanList(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/notePublishPlan/listByAuthorIdAndTimeRange`,
      method: 'get',
      params,
    });
  },

  // 查询草稿是否已推送/发布
  getDraftPushStatus(params) {
    return request({
      baseURL: BFZ_BFF,
      url: `/xhs/note/push/judgmentPushed`,
      method: 'get',
      params,
    });
  }
};
